// Libraries
import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useDispatch, useSelector} from 'react-redux';

// Styles
import classes from './ProfileDropdown.module.scss';

// Components
import CustomInputStyleWrapper from '../../layout/CustomInputStyleWrapper';
import CustomPopover from '../../layout/CustomPopover';

// Constants & Utils
import {useAuth} from '../../context/AuthUserContext';

// Actions
import {hideLoader, showLoader} from '../../store/actions/globalActions';
import {userLogout} from '../../store/actions/userActions';

// Constants & Utils
import {isUserAdmin} from '../../utils/commonUtils';


export default function ProfileDropDown() {
    const router = useRouter();
    const dispatch = useDispatch();
    const {signOut, loading, authUser} = useAuth();
    const userData = useSelector((state) => state?.userData);
    const {getUserDetailsResponse, getUserDetailsLoading, getUserDetailsFulfilled, getUserDetailsFailed} = userData;

    const [profileObj, setProfileObj] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (getUserDetailsResponse?.id) {
            setProfileObj(getUserDetailsResponse);
            setIsAdmin(isUserAdmin(getUserDetailsResponse));
        }
    }, [getUserDetailsResponse]);


    useEffect(() => {
        if (getUserDetailsLoading)
            dispatch(showLoader());
    }, [getUserDetailsLoading]);

    useEffect(() => {
        if (getUserDetailsFulfilled || getUserDetailsFailed)
            dispatch(hideLoader());
    }, [getUserDetailsFulfilled, getUserDetailsFailed]);

    useEffect(() => {
        if (!loading && !authUser)
            router.push('/login').then();
    }, [authUser, loading]);


    return (
        <CustomPopover
            content={
                <>
                    <CustomInputStyleWrapper
                        className={'display--flex flex__alignItems--center flex__justifyContent--between text__align--left width--full cursor--pointer'}
                        size={'medium'}
                        topFormElement={true}
                        borderBottom={true}
                        bgHighlight={true}
                        link={'/profile'}
                    >Profile
                        <span className={'icon-arrow-right marginLeft--l3 ' + ' ' + classes.navTabArrow}></span>
                    </CustomInputStyleWrapper>
                    {isAdmin && <CustomInputStyleWrapper
                        className={'display--flex flex__alignItems--center flex__justifyContent--between text__align--left width--full cursor--pointer'}
                        size={'medium'}
                        borderBottom={true}
                        bgHighlight={true}
                        link={'/manage-people'}
                    >Manage people
                        <span className={'icon-arrow-right marginLeft--l3' + ' ' + classes.navTabArrow}></span>
                    </CustomInputStyleWrapper>
                    }
                    <CustomInputStyleWrapper
                        className={'display--flex flex__alignItems--center flex__justifyContent--start text__align--left width--full cursor--pointer'}
                        size={'medium'}
                        bottomFormElement={true}
                        bgHighlight={true}
                        onAction={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            dispatch(userLogout());
                            signOut();  //Don't add then here
                        }}
                    >Logout</CustomInputStyleWrapper>
                </>
            }
        >
            <div className={classes.profileDropdownContainer}>
                {
                    profileObj?.photoURL?.trim()?.length ?
                        <div
                            className={classes.profilePicture}
                            style={{backgroundImage: `url(${profileObj?.photoURL})`}}>
                        </div>
                        :
                        profileObj?.displayName?.trim().length ?
                            <div
                                className={classes.profilePicture}>
                                <div>{profileObj?.displayName?.slice(0, 1)?.toUpperCase()}</div>
                            </div>
                            :
                            <div
                                className={classes.profilePicture}>
                                {profileObj?.email?.slice(0, 1)?.toUpperCase()}
                            </div>
                }
                <span className='icon-arrow-down marginLeft--l1'></span>
            </div>
        </CustomPopover>
    );

}