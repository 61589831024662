// Libraries
import React from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';

// Styles
import classes from './Header.module.scss';

// Components
import EnvComponent from '../components/home/EnvComponent';
import ProfileDropDown from '../components/home/ProfileDropdown';
import {
    ALLIANCE_RAVENS_BASE_ROUTE,
    GC_SHEET_PAGE_ROUTE,
    CONTESTS_LISTING_PAGE_ROUTE,
    ELEMENT_BUILDER_BASE_ROUTE,
    FAM_HOME_PAGE_ROUTE,
    FAMPLAY_BASE_ROUTE,
    KILL_SWITCH_BASE_ROUTE,
    MASTER_OF_COIN_PAGE_ROUTE,
    PAGES_LISTING_PAGE_ROUTE,
    NAMASPAY_BASE_ROUTE
} from '../constants/routeConstants';

function Header({leftElement, rightElement, hideNavigation}) {
    const router = useRouter();

    const renderHeaderNavigation = () => {
        return (
            <div className='display--flex flex__alignItems--center marginRight--l4'>
                <Link href={PAGES_LISTING_PAGE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(ELEMENT_BUILDER_BASE_ROUTE) && classes.active)}>Element
                    builder</a></Link>
                <Link href={CONTESTS_LISTING_PAGE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(FAMPLAY_BASE_ROUTE) && classes.active)}>FamPlay</a></Link>
                <Link href={FAM_HOME_PAGE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(FAM_HOME_PAGE_ROUTE) && classes.active)}>FamHome</a></Link>
                <Link href={MASTER_OF_COIN_PAGE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(MASTER_OF_COIN_PAGE_ROUTE) && classes.active)}>Coin
                    Master</a></Link>
                <Link href={KILL_SWITCH_BASE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(KILL_SWITCH_BASE_ROUTE) && classes.active)}>Kill
                    Switch</a></Link>
                <Link href={NAMASPAY_BASE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(NAMASPAY_BASE_ROUTE) && classes.active)}>NamasPay</a></Link>
                <Link href={GC_SHEET_PAGE_ROUTE}><a
                    className={classes.navTab + ' ' + (router.asPath?.includes(ALLIANCE_RAVENS_BASE_ROUTE) && classes.active)}>Alliance Ravens</a></Link>
            </div>
        );
    };

    return (
        <header className={classes.header + ' pagePadd__x'}>
            <div className='marginTop--l2 marginBottom--l2'>
                {
                    leftElement
                        ?
                        leftElement
                        :
                        <Link href='/'>
                            <img alt='' className={classes.logo} src='/assets/images/fampay-logo-text.png'/>
                        </Link>
                }
            </div>

            {rightElement ||
                <div className='display--flex flex__alignItems--center flex__justifyContent--between'>
                    {!hideNavigation && renderHeaderNavigation()}
                    <EnvComponent/>
                    <ProfileDropDown/>
                </div>
            }
        </header>
    );
}

export default Header;
