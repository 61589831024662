export const CAMPAIGNS_LISTING_PAGE_ROUTE = '/campaigns/';
export const CAMPAIGN_CREATION_PAGE_ROUTE = '/campaigns/create/';
export const CAMPAIGN_DETAIL_PAGE_BASE_ROUTE = '/campaigns/';
export const CAMPAIGNS_CONTESTS_LISTING_PAGE_ROUTE = '/campaigns/review-contests/';

export const ELEMENT_BUILDER_BASE_ROUTE = '/element-builder/';

export const PAGES_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'pages/';
export const PAGE_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'pages/create/';
export const PAGE_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'pages/';

export const SECTIONS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'sections/';
export const SECTION_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'sections/create/';
export const SECTION_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'sections/';

export const WIDGET_GROUPS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widget-groups/';
export const WIDGET_GROUP_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widget-groups/create/';
export const WIDGET_GROUP_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widget-groups/';

export const WIDGETS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widgets/';
export const WIDGET_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widgets/create/';
export const WIDGET_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'widgets/';

export const MODAL_DIALOG_GROUPS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialog-groups/';
export const MODAL_DIALOG_GROUP_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialog-groups/create/';
export const MODAL_DIALOG_GROUP_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialog-groups/';

export const MODAL_DIALOGS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialogs/';
export const MODAL_DIALOG_CREATION_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialogs/create/';
export const MODAL_DIALOG_DETAIL_PAGE_BASE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'modal-dialogs/';

export const ASSETS_LISTING_PAGE_ROUTE = ELEMENT_BUILDER_BASE_ROUTE + 'assets/';


export const FAMPLAY_BASE_ROUTE = '/challenges/';

export const CONTESTS_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'contests/';
export const CONTEST_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'contests/create/';
export const CONTEST_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'contests/';

export const CONTESTS_MAMMOTH_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'mammoth/';
export const CONTESTS_NIMBLE_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'nimble/';

export const ACTIVITIES_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'activities/';
export const ACTIVITY_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'activities/create/';
export const ACTIVITY_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'activities/';

export const PRODUCTS_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'products/';
export const PRODUCT_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'products/create/';
export const PRODUCT_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'products/';

export const BRANDS_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'brands/';
export const BRAND_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'brands/create/';
export const BRAND_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'brands/';

export const MISSIONS_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'missions/';
export const MISSION_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'missions/create/';
export const MISSION_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'missions/';

export const BOTS_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'bots';
export const BOT_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'bots/create';

export const FEATURED_LISTING_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'featured-banners/';
export const FEATURED_CREATION_PAGE_ROUTE = FAMPLAY_BASE_ROUTE + 'featured-banners/create/';
export const FEATURED_DETAIL_PAGE_BASE_ROUTE = FAMPLAY_BASE_ROUTE + 'featured-banners/';

export const MASTER_OF_COIN_PAGE_ROUTE = '/master-of-coin/';
export const MASTER_OF_COIN_SYNC_PAGE_ROUTE = MASTER_OF_COIN_PAGE_ROUTE + 'sync/';
export const MASTER_OF_COIN_ANALYTICS_PAGE_ROUTE = MASTER_OF_COIN_PAGE_ROUTE + 'analytics/';

export const FAM_HOME_PAGE_ROUTE = '/fam-home/';
export const FAM_HOME_SYNC_PAGE_ROUTE = FAM_HOME_PAGE_ROUTE + 'sync/';
export const FAM_HOME_ANALYTICS_PAGE_ROUTE = FAM_HOME_PAGE_ROUTE + 'analytics/';

export const STORIES_LISTING_PAGE_ROUTE = FAM_HOME_PAGE_ROUTE + 'stories/';
export const STORY_CREATION_PAGE_ROUTE = FAM_HOME_PAGE_ROUTE + 'stories/create/';
export const STORY_DETAIL_PAGE_BASE_ROUTE = FAM_HOME_PAGE_ROUTE + 'stories/';

export const KILL_SWITCH_BASE_ROUTE = '/kill-switch/';
export const KILL_SWITCH_CREATION_PAGE_ROUTE = KILL_SWITCH_BASE_ROUTE + 'create/';

export const EXPERIMENT_GROUP_LISTING_PAGE_ROUTE = KILL_SWITCH_BASE_ROUTE + 'experiment-groups/';
export const EXPERIMENT_GROUP_CREATION_PAGE_ROUTE = KILL_SWITCH_BASE_ROUTE + 'experiment-groups/create/';

export const EXPERIMENT_LISTING_PAGE_ROUTE = KILL_SWITCH_BASE_ROUTE + 'experiments/';
export const EXPERIMENT_CREATION_PAGE_ROUTE = KILL_SWITCH_BASE_ROUTE + 'experiments/create/';

export const NAMASPAY_BASE_ROUTE = '/namaspay/';
export const NAMASPAY_PARTNERS_LISTING_PAGE_ROUTE = NAMASPAY_BASE_ROUTE + 'partners/';
export const NAMASPAY_PARTNERS_CREATION_PAGE_ROUTE = NAMASPAY_BASE_ROUTE + 'partners/create';
export const NAMASPAY_PARTNER_DETAIL_PAGE_BASE_ROUTE = NAMASPAY_BASE_ROUTE + 'partners/';

export const ALLIANCE_RAVENS_BASE_ROUTE = '/alliance-ravens/';
export const GC_SHEET_PAGE_ROUTE = ALLIANCE_RAVENS_BASE_ROUTE + 'gift-cards/';
export const GC_SHEET_SYNC_ROUTE = ALLIANCE_RAVENS_BASE_ROUTE + 'gift-cards/sync';

export const PARTNERSHIP_SHEET_PAGE_ROUTE = ALLIANCE_RAVENS_BASE_ROUTE + 'partnerships/';
export const PARTNERSHIP_SHEET_SYNC_ROUTE = ALLIANCE_RAVENS_BASE_ROUTE + 'partnerships/sync/';



// App Deeplinks
export const GPRC_HOME_DEEPLINK = 'fp://fampay.in/alert/GC006/';
export const RECHARGE_HOME_DEEPLINK = 'fp://fampay.in/alert/PREPAIDRECHARGE/';
export const GIFT_CARD_HOME_DEEPLINK = 'fp://fampay.in/hcc_screen/?slugs=gcscreen&fp_title=Gift%20Cards&tb_dark=true&bg_color=1e1e1e&tb_bg_color=1e1e1e';
export const CARD_HOME_SCREEN_DEEPLINK = 'fp://fampay.in/home/my_card_standalone/?show_details_direct=true';
export const SWIGGY_DEEPLINK = 'fp://fampay.in/alert/FPLSWIGGY/';
export const FLIPKART_DEEPLINK = 'fp://fampay.in/alert/FPLFLIPKART/';


export const MAIN_NAVIGATION_ITEMS = [
    {
        title: 'Element Builder',
        link: PAGES_LISTING_PAGE_ROUTE,
        image: '/assets/images/logos/element-builder-logo.png'
    },
    {
        title: 'Explore (FamHome)',
        link: FAM_HOME_PAGE_ROUTE,
        image: '/assets/images/logos/fam-home.png',
    },
    {
        title: 'Challenges',
        link: CONTESTS_LISTING_PAGE_ROUTE,
        image: '/assets/images/logos/famplay-logo.png'
    },
    // {
    //     title: 'Campaigns',
    //     link: CAMPAIGNS_LISTING_PAGE_ROUTE,
    //     image: '/assets/images/logos/campaigns-logo.png'
    // },
    {
        title: 'Master of Coin',
        link: MASTER_OF_COIN_PAGE_ROUTE,
        image: '/assets/images/logos/master-of-coin.png'
    },
    {
        title: 'Kill Switch',
        link: KILL_SWITCH_BASE_ROUTE,
        image: '/assets/images/logos/kill-switch.png?v=2',
    },
    {
        title: 'NamasPay',
        link: NAMASPAY_BASE_ROUTE,
        image: '/assets/images/logos/namaspay-logo.jpg'
    },
    {
        title: 'Alliance Ravens',
        link: GC_SHEET_PAGE_ROUTE,
        image: '/assets/images/logos/alliance-ravens-logo.png'
    },
];
