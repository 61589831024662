// Libraries
import React, {useState} from 'react';

// Styles
import classes from './EnvComponent.module.scss';
import CustomInputStyleWrapper from '../../layout/CustomInputStyleWrapper';
import CustomPopover from '../../layout/CustomPopover';


export default function EnvComponent() {
    const env = localStorage?.getItem('env') ? localStorage?.getItem('env') : 'live';
    const devDatabases = ['DB #1', 'DB #2', 'DB #3'];
    const [currentDB, setCurrentDB] = useState('DB #1');

    if (env.toLowerCase() === 'live')
        return (
            <div className='display--flex flex__justifyContent--start flex__alignItems--center'>
                <span className={classes.statusIcon}></span>
                <div className='fontSize--h4 font__weight--bold'>Live</div>
            </div>
        );
    return (
        <CustomPopover
            content=
                {
                    <>
                        {
                            devDatabases.map((db, index) => (
                                <CustomInputStyleWrapper
                                    key={index}
                                    className={'display--flex flex__alignItems--center flex__justifyContent--between text__align--left width--full cursor--pointer'}
                                    size={'small'}
                                    topFormElement={true}
                                    hoverBackground={true}
                                    onAction={() => {
                                        setCurrentDB(db);
                                    }}
                                >{db}</CustomInputStyleWrapper>
                            ))
                        }
                    </>
                }>
            <div className='display--flex flex__justifyContent--start flex__alignItems--center cursor--pointer'>
                <span className={classes.statusIcon}></span>
                <div className='fontSize--h4 font__weight--bold'>{currentDB}</div>
            </div>
        </CustomPopover>
    );

}